.footer {
	width: 100%;
	@extend %flex-center;
	border-top: .1rem solid var(--gray-300-rgba);
	padding: 6rem 0;

	&__wrapper {
		width: 100%;
		max-width: 120rem;
		@extend %flex-column-center-h;
	}

	&__social {
		@extend %flex-wrap;
		margin-bottom: 6rem;
		&-icon {
			margin-right: 4.5rem;
			&:last-of-type {
				margin-right: 0;
			}
			img {
				max-width: 6rem;
			}
		}
	}

	&__list {
		padding: 0 8.33%;
		@extend %flex-center;
		margin-bottom: 2rem;

		&-item {
			position: relative;
			margin-left: 3.8rem;

			&:first-of-type {
				margin-left: 0;
				&::before {
					display: none;
				}
			}

			&::before {
				content: '';
				@include size(.4rem);
				border-radius: 50%;
				background: var(--gray-400);
				@include absolute(top 50% left -2rem);
				transform: translateY(-50%);
			}
		}
	}

	&__logo {
		margin-bottom: 4rem;
		padding: 0 2.5rem;
	}
}