.banner-text {
	width: 100%;
	@extend %flex-center;
	margin-bottom: 10rem;

	@include bp('extra-large') {
		padding: 0 2.5rem;
	}

	&__wrapper {
		width: 100%;
		max-width: 120rem;
	}

	&__banner {
		margin-bottom: 5rem;
	}

	&__content {
		padding: 0 8.33%;
	}

	&__title {
		margin-bottom: 2rem;
	}

	&__text {
		&:not(:last-of-type) {
			margin-bottom: 4rem;
		}
	}
}