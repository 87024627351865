html {
	font-size: var(--base-font);
	font-family: var(--futura);
}

.banner-text {
	&__title {
		font-size: 2.9rem;
		font-weight: 500;
		color: var(--cream);
	}

	&__text {
		font-size: 2rem;
		line-height: line-height(25, 20);
		font-weight: 400;
		color: var(--gray-400);

		&--small {
			font-size: 1.2rem;
			line-height: line-height(14, 12);
			color: var(--gray-400);
		}
	}
}

.filter {
	&__title {
		font-size: 2.9rem;
		font-weight: 400;
		color: var(--gray-200);
		text-transform: uppercase;
		text-align: center;
	}

	&__form-select {
		select {
			font-size: 1.4rem;
			font-weight: 400;
			color: var(--gray-300);
		}
	}

	&__item {
		&-title {
			font-size: 1.6rem;
			font-weight: 500;
			color: var(--cream);
		}
		&-address,
		&-zip,
		&-country,
		&-number {
			font-size: 1.6rem;
			line-height: line-height(22, 16);
			font-weight: 400;
			color: var(--gray-400);
		}
	}

	&__load {
		font-size: 1.6rem;
		font-weight: 500;
		color: var(--cream);
		text-decoration: underline;
	}
}

.footer {
	&__list {
		&-item {
			font-size: 1.4rem;
			text-transform: uppercase;
			color: var(--gray-400);
			line-height: line-height(30, 14);
		}
	}

	&__copy {
		font-size: 1.4rem;
		text-transform: uppercase;
		color: var(--gray-400);
		line-height: line-height(30, 14);
	}
}

//include only the following properties:
// font-family
// font-size
// font-weight
// line-height
// text-decoration
// text-align
// text-transform
// color


// blocks sort by order A-Z