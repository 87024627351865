//CSS vars
:root {
	--base-font: 62.5%;
	--fw-bold: 700;
	--black: #000;
	--white: #fff;
	--selection: #f60;
	--spacing: 1rem;
	--gray-200: #808080;
	--gray-300: #5f6a72;
	--gray-300-rgba: rgba(95, 106, 114, .3);
	--gray-400: #626a6e;
	--cream: #f08874;
	--futura: 'futura-pt';
}

//breakpoint vars
$extra-small: 22.5em; //360px
$small: 36em; //576px
$medium: 48em; //768px
$large: 64em; //1024px
$extra-large: 75em; //1200px
$wide: 90em; //1440px
$extra-wide: 100em; //1600px

//breakpoints
$breakpoints: ( //types
	'extra-small': $extra-small,
	'small': $small,
	'medium': $medium,
	'large': $large,
	'extra-large': $extra-large,
	'wide': $wide,
	'extra-wide': $extra-wide,
	//finish types
);

// Caching current breakpoint
// Not meant to be manually edited
$default-breakpoint: root;
$current-breakpoint: $default-breakpoint;

// Caching existing placeholders
// Not meant to be manually edited
$placeholders: ();

//effects
$ease: ( // list from https://css-tricks.com/snippets/sass/easing-map-get-function/
	in-quad: cubic-bezier(.55, .085, .68, .53),
	in-cubic: cubic-bezier(.55, .055, .675, .19),
	in-quart: cubic-bezier(.895, .03, .685, .22),
	in-quint: cubic-bezier(.755, .05, .855, .06),
	in-sine: cubic-bezier(.47, 0, .745, .715),
	in-expo: cubic-bezier(.95, .05, .795, .035),
	in-circ: cubic-bezier(.6, .04, .98, .335),
	in-back: cubic-bezier(.6, -.28, .735, .045),
	out-quad: cubic-bezier(.25, .46, .45, .94),
	out-cubic: cubic-bezier(.215, .61, .355, 1),
	out-quart: cubic-bezier(.165, .84, .44, 1),
	out-quint: cubic-bezier(.23, 1, .32, 1),
	out-sine: cubic-bezier(.39, .575, .565, 1),
	out-expo: cubic-bezier(.19, 1, .22, 1),
	out-circ: cubic-bezier(.075, .82, .165, 1),
	out-back: cubic-bezier(.175, .885, .32, 1.275),
	in-out-quad: cubic-bezier(.455, .03, .515, .955),
	in-out-cubic: cubic-bezier(.645, .045, .355, 1),
	in-out-quart: cubic-bezier(.77, 0, .175, 1),
	in-out-quint: cubic-bezier(.86, 0, .07, 1),
	in-out-sine: cubic-bezier(.445, .05, .55, .95),
	in-out-expo: cubic-bezier(1, 0, 0, 1),
	in-out-circ: cubic-bezier(.785, .135, .15, .86),
	in-out-back: cubic-bezier(.68, -.55, .265, 1.55),
	// easing customs
);

//z-index
$z-index: ( //values
	'pothole': -1,
	'basement': 0,
	'sidewalk': 1,
	'bungalow': 40,
	'bungalow-chimney': 50,
	'house': 60,
	'tower': 70,
	'skyscrapper': 80,
	'sky': 90,
	'satellite': 100,
	//finish z-index
);