.header {
	width: 100%;
	@extend %flex-center;
	position: relative;
	border-bottom: .1rem solid var(--gray-300-rgba);
	padding: 2.5rem;

	&__wrapper {
		width: 100%;
		@extend %flex-between;
		align-items: center;
		max-width: 120rem;
		position: relative;

		@include bp('small') {
			justify-content: center
		}
	}

	&__social {
		@extend %flex-wrap;

		@include bp('small') {
			@include absolute(top 50% right 0);
			transform: translateY(-50%);
		}

		&-icon {
			margin-right: 2rem;

			&:last-of-type {
				margin-right: 0;
			}

			img {
				max-width: 2.5rem;
			}
		}
	}

	&__logo {
		img {
			max-width: 15rem;
		}
	}
}