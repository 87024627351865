.filter {
	width: 100%;
	@extend %flex-center;
	margin-bottom: 10rem;

	&__wrapper {
		width: 100%;
		max-width: 120rem;
		@extend %flex-column-center-h;
		overflow: hidden;
	}

	&__title {
		position: relative;
		margin-bottom: 6rem;
		max-width: 60%;

		&::before {
			content: '';
			@include size(100%, .1rem);
			background-color: var(--cream);
			@include absolute(top 50%);
			left: calc(-100% + (-2.5rem));
			transform: translateY(-50%);
		}

		&::after {
			content: '';
			@include size(100%, .1rem);
			background-color: var(--cream);
			@include absolute(top 50%);
			left: calc(100% + 2.5rem);
			transform: translateY(-50%);
		}
	}

	&__form {
		width: 100%;
		max-width: 30rem;
		margin-bottom: 7rem;

		&-select {
			width: 100%;
			border: .1rem solid var(--cream);
			position: relative;

			&::before {
				content: '';
				@include absolute(top 0 right 0);
				@include size(3.5rem, 100%);
				background: var(--cream) url('/assets/img/arrow.svg') no-repeat 50% 60%;
				pointer-events: none;
			}

			select {
				width: 100%;
				padding: 1rem 1.5rem;
			}
		}
	}

	&__items {
		width: 100%;
		padding: 0 8.33%;
		@extend %flex-wrap;
		margin-bottom: 4rem;
	}

	&__item {
		flex-basis: 100%;
		margin-bottom: 4rem;
		@include bp('medium') {
			flex-basis: calc(50% - 1.5rem);
		}
		@include bp('large') {
			flex-basis: calc(25% - (9rem / 4));
			margin-right: 3rem;
		}
		&:nth-of-type(2n-1) {
			margin-right: 3rem;
		}
		&:nth-of-type(4n) {
			margin-right: 0;
		}
	}

	&__load {
		position: relative;
		padding-right: 3rem;
		cursor: pointer;
		&::after {
			content: '';
			@include size(1.8rem);
			@include absolute(top 50% right 0);
			transform: translateY(-50%);
			background: url('/assets/img/plus.svg') no-repeat center / cover;
		}
	}
}